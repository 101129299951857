import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PhotoGrid from "../components/index/photo-grid"

const GalleryPage = () => (
  <Layout>
    <SEO title="Home" />
    <PhotoGrid />
  </Layout>
)

export default GalleryPage
